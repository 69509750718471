import * as React from 'react'
import Contact from './contact'

interface Props {
  location: {
    pathname: string
    state: {
      topic?: string
    }
  }
}

const ContactUsThankYou = (props: Props): JSX.Element => {
  return <Contact location={props.location} showThankYou />
}

export default ContactUsThankYou
